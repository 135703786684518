import React from "react";
import BlogTeaser from "../components/blog-teaser";
import { useStaticQuery, graphql } from "gatsby";
import { useTheme, Theme, Container, Grid, Typography, Box } from "@material-ui/core";
import Hero from "../components/hero";
import Section from "../components/section";
import ReactMarkdown from "react-markdown";
import ContentBlock from "../components/contentBlock";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import SEO from "../components/seo";
import MainLayout from "../layouts/mainLayout";
import SubscribeBlock from "../composed/subscribe-block";
import LinkButton from "../components/link-button";

interface LocationProps {
    location: Location;
}

const ShapeContain = styled(Grid)`
    overflow: hidden;
`;

const ShapedImage = styled(Img)`
    clip-path: url(#what-clip)
`;

const AboutPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
    query {
        seo: aboutJson(block: {eq: "seo" }) {
            title
            description
            image {
                childImageSharp {
                    resize(width: 750, height: 300, cropFocus: ENTROPY) {
                        src
                        width
                        height
                    }
                }
            }
        }
        hero: aboutJson(block: {eq: "hero"}) {
            title
            tagline
            ctaText
            ctaUrl
            cta2Text
            cta2Url
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160, maxHeight: 3120) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        about: aboutJson(block: {eq: "about"}) {
            content
            quote
            ctaUrl
            ctaText
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400, maxHeight: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
    `);

    return (
        <MainLayout transparentNav>
            <SEO {...data.seo} location={props.location} />
            <Hero {...data.hero} overlayColor={theme.palette.secondary.light} heroHeight={65} />
            <Section tightBottom>
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Box p={4}>
                                <Img fluid={data.about.image.childImageSharp.fluid} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ReactMarkdown source={data.about.content} />
                        </Grid>
                    </Grid>
                </Container>
            </Section>

            <Section tightTop tightBottom>
                <Container>
                    <Typography variant="h2" align="center" gutterBottom>{data.about.quote}</Typography>
                    <Box textAlign="center" pt={3}>
                        <LinkButton url={data.about.ctaUrl} variant="contained" color="primary" size="large">{data.about.ctaText}</LinkButton>
                    </Box>
                </Container>
            </Section>
            <BlogTeaser />
            <SubscribeBlock />
        </MainLayout>
    )
}

export default AboutPage